.message-list {
    max-width: 500px;
    width: 100%;
}

.message-container {
    display: flex;
    flex-direction: row;
    padding: 0.5rem;
    border-bottom: 1px solid #eeeeee;
}

.user, .date {
    color: #888888;
}

.user {
    min-width: 5em
}

.message {
    flex-grow: 1;
}
